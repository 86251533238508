// @flow
import emailjs from "@emailjs/browser";

type Props = {
  from_name: string;
  message: string;
  reply_to: string;
  subject: string;
  onSuccess: any;
  onError: any;
};
export const SendEmail = ({
  from_name,
  message,
  reply_to,
  subject,
  onError,
  onSuccess,
}: Props) => {
  var templateParams = {
    from_name,
    to_email: process.env.REACT_APP_MAIL,
    to_name: process.env.REACT_APP_MESSAGE_TO,
    reply_to,
    message,
    subject,
  };

  emailjs
    .send(
      process.env.REACT_APP_EMAILJS_ID ?? "",
      process.env.REACT_APP_EMAIL_TEMPLATE_ID ?? "",
      templateParams,
      process.env.REACT_APP_EMAIL_PUBLIC_KEY,
    )
    .then(
      function (response) {
        onSuccess();
      },
      function (error) {
        onError();
      },
    );
};
