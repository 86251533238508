// @flow
import * as React from "react";
import { useRef } from "react";
import { map } from "lodash";
import { useIsVisible } from "../hooks/useIsVisible";

type Props = {};
export const Clients = (props: Props) => {
  const images = [
    { src: "img/clients/client-1.png", alt: "CSQ" },
    { src: "img/clients/client-2.png", alt: "Plan Jaba" },
    { src: "img/clients/client-3.png", alt: "Naturaleza Secreta" },
    { src: "img/clients/client-4.png", alt: "Cuba Electronica" },
    { src: "img/clients/client-5.png", alt: "Alabao" },
    { src: "img/clients/client-6.png", alt: "Trustly" },
  ];

  const refClients = useRef<any>();
  const isVisibleTitle = useIsVisible(refClients);

  return (
    <div id={"clients"} className={"bg-gray-300"}>
      <div
        ref={refClients}
        className={`sm:px-40 h-80 grid grid-cols-3 lg:grid-cols-6 lg:h-40 items-center justify-items-center transition-all ease-in duration-700 ${
          isVisibleTitle ? "scale-100 opacity-100" : "scale-75 opacity-0"
        }`}
      >
        {map(images, ({ src, alt }) => (
          <img key={alt} className={"w-24 grayscale"} src={src} alt={alt} />
        ))}
      </div>
    </div>
  );
};
