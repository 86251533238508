// @flow
import * as React from "react";
import { useRef, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { useIsVisible } from "../hooks/useIsVisible";

type AccordionProps = {
  open: boolean;
  setOpen: any;
  text: string;
  title: string;
  index: number;
};
const Accordion = ({ open, setOpen, text, title, index }: AccordionProps) => {
  return (
    <div className={"bg-white bg-opacity-70 rounded-sm text-black p-4"}>
      <div
        onClick={setOpen}
        className={`flex font-['Jost'] justify-between text-lg hover:cursor-pointer hover:text-golden select-none ${
          open ? "text-golden" : ""
        } `}
      >
        <div className={"flex gap-2 text-start"}>
          <b className={"text-golden font-bold"}>0{index}</b>
          <h3 className={"font-medium"}>{title}:</h3>
        </div>
        <button className={"w-6"}>
          <ChevronDownIcon />
        </button>
      </div>

      <div
        className={`overflow-hidden text-start transition-all ease-in-out duration-500 ${
          open ? "max-h-screen" : "max-h-0"
        }`}
      >
        <p className={"mt-2"}>{text}</p>
      </div>
    </div>
  );
};

type Props = {};
export const WhyUs = (props: Props) => {
  const [open, setOpen] = useState(0);
  const refImage = useRef<any>();
  const isVisibleTitle = useIsVisible(refImage);

  const handleOpen = (index: number) => {
    return open === index ? setOpen(0) : setOpen(index);
  };

  return (
    <div className={"py-20 bg-white bg-opacity-10"}>
      <div
        className={
          "flex flex-col items-center lg:items-start lg:flex-row-reverse px-5 sm:px-20 xl:px-40 lg:gap-10 xl:gap-40"
        }
      >
        <img
          ref={refImage}
          className={`w-3/4 lg:w-[520px] transition-all ease-in duration-700 ${
            isVisibleTitle ? "scale-100 opacity-100" : "scale-75 opacity-0"
          }`}
          src={"img/why-us.png"}
          alt={"whyUs"}
        />

        <div
          id={"why_us_text"}
          className={`space-y-4 xl:mt-12 lg:text-start mt-4 transition-all ease-in duration-700 ${
            isVisibleTitle ? "scale-100 opacity-100" : "scale-75 opacity-0"
          }`}
        >
          <h2 className={"text-4xl"}>
            Inspiración, colaboración, <b>"resultados"</b>
          </h2>
          <h3>
            Trabajamos con agilidad, adaptabilidad y un uso eficiente de
            recursos para ofrecer soluciones visualesmente atractivas y
            efectivas.{" "}
          </h3>
          <div className={"space-y-4"}>
            <Accordion
              open={open === 1}
              index={1}
              setOpen={() => handleOpen(1)}
              title={"Enfoque centrado en el cliente"}
              text={
                "Valoramos la simplicidad porque permite satisfacer mejor las necesidades y expectativas de los clientes. Al simplificar la experiencia del cliente, facilitamos la conexión y el compromiso, generando relaciones duraderas y fidelidad hacia la marca."
              }
            />
            <Accordion
              open={open === 2}
              index={2}
              setOpen={() => handleOpen(2)}
              title={"Enfoque multidisciplinario"}
              text={
                "Promociona la ventaja de tener un equipo diverso de profesionales con habilidades complementarias, incluyendo diseñadores, especialistas en marketing digital, expertos en análisis de datos, programadores, fotógrafos, especialistas audiovisuales y especialistas en redes sociales. Destaca la capacidad de tu agencia para ofrecer una gama completa de servicios de marketing bajo un mismo techo."
              }
            />
            <Accordion
              open={open === 3}
              index={3}
              setOpen={() => handleOpen(3)}
              title={"Resultados medibles y análisis de datos"}
              text={
                "Capacidad de generar y medir resultados tangibles. Promocionamos un enfoque basado en datos y el análisis de resultados para identificar áreas de mejora y optimizar las estrategias en tiempo real. Empleamos métricas clave y herramientas de seguimiento para evaluar el rendimiento y tomar decisiones informadas para beneficio de los clientes."
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};
