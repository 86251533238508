// @flow
import * as React from "react";
import { useRef, useState } from "react";
import { useIsVisible } from "../hooks/useIsVisible";
import {
  DevicePhoneMobileIcon,
  EnvelopeIcon,
  MapPinIcon,
} from "@heroicons/react/24/outline";
import { SendEmail } from "../utils/SendEmail";

type Props = {};
export const ContactUs = (props: Props) => {
  const refAbout = useRef<any>();
  const refName = useRef<any>();
  const refMail = useRef<any>();
  const refSubject = useRef<any>();
  const refMessage = useRef<any>();
  const isVisibleTitle = useIsVisible(refAbout);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(
    "Mensaje enviado. Muchas gracias por ponerse en contacto.",
  );
  const [error, setError] = useState(false);

  const sendEmail = (e: any) => {
    e.preventDefault();
    SendEmail({
      message: refMessage.current.value,
      from_name: refName.current.value,
      reply_to: refMail.current.value,
      subject: refSubject.current.value,
      onSuccess: () => {
        setError(false);
        setMessage("Mensaje enviado. Muchas gracias por ponerse en contacto.");
        setOpen(true);
        setInterval(() => {
          setOpen(false);
        }, 5000);
      },
      onError: () => {
        setError(true);
        setMessage(
          "Lo sentimos hubo un problema al enviar el mensaje. Inténtelo más tarde.",
        );
        setOpen(true);
        setInterval(() => {
          setOpen(false);
        }, 5000);
      },
    });
    e.target.reset();
  };

  return (
    <div id={"contact"} className={"py-20 scroll-smooth"}>
      <div
        className={`transition-all ease-in duration-700 ${
          isVisibleTitle
            ? "translate-y-0 opacity-100"
            : "translate-y-20 opacity-0"
        }`}
      >
        <div className={"section-title relative pb-10"} ref={refAbout}>
          <h2 className={"text-4xl font-bold uppercase text-golden"}>
            Contáctanos
          </h2>
        </div>
        <p className={"mt-6"}>Juntos podemos crecer</p>

        <div
          className={
            "grid lg:grid-cols-3 mt-6 text-justify gap-4 mx-4 sm:mx-10 md:mx-14 xl:mx-32 2xl:mx-72"
          }
        >
          <div className={"border-y-2 border-golden p-6 space-y-3 bg-white/10"}>
            {[
              {
                label: "Localización",
                text: process.env.REACT_APP_ADDRESS,
                Icon: MapPinIcon,
              },
              {
                label: "Email",
                text: process.env.REACT_APP_MAIL,
                Icon: EnvelopeIcon,
              },
              {
                label: "Celular",
                text: process.env.REACT_APP_PHONE,
                Icon: DevicePhoneMobileIcon,
              },
            ].map(({ label, text, Icon }) => (
              <div key={label} className={"flex gap-4 items-center"}>
                <Icon
                  className={
                    "w-10 p-2 bg-golden/50 rounded-full text-golden shrink-0"
                  }
                />
                <div className={"flex-wrap"}>
                  <p className={"text-2xl font-['Jost']"}>{label}:</p>
                  <p>{text}</p>
                </div>
              </div>
            ))}
          </div>

          <div
            className={
              "border-y-2 border-golden p-6 space-y-3 font-['Jost'] bg-white/10 lg:col-span-2"
            }
          >
            <form onSubmit={sendEmail}>
              <div className={"grid sm:grid-cols-2 gap-2 sm:gap-6 "}>
                <div className={"grid"}>
                  <label>Su Nombre:</label>
                  <input
                    ref={refName}
                    className={"rounded-md bg-white/10 h-10"}
                    type={"text"}
                    required
                  />
                </div>
                <div className={"grid"}>
                  <label>Su Correo:</label>
                  <input
                    ref={refMail}
                    className={"rounded-md bg-white/10 h-10"}
                    type={"text"}
                    required
                  />
                </div>
              </div>

              <div className={"grid"}>
                <label>Asunto:</label>
                <input
                  ref={refSubject}
                  className={"rounded-md bg-white/10 h-10"}
                  type={"text"}
                  required
                />
              </div>
              <div className={"grid"}>
                <label>Mensaje:</label>
                <textarea
                  ref={refMessage}
                  className={"rounded-md bg-white/10"}
                  rows={6}
                  required
                ></textarea>
              </div>

              <div className={"text-center mt-4"}>
                <button
                  className='text-white font-["Jost"] font-medium py-2 px-6 text-lg bg-golden transition-colors ease-in-out hover:bg-golden/80 duration-500 rounded-full'
                  type={"submit"}
                >
                  Enviar Mensaje
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
        id={"notification"}
        className={"fixed top-20 w-full justify-center flex"}
      >
        <div
          className={`${
            error ? "bg-red-500" : "bg-golden"
          } rounded-full px-6 py-2 text-black font-['Jost'] font-bold transition-opacity ease-in-out duration-700 ${
            open ? "opacity-100" : "opacity-0"
          }`}
        >
          {message}
        </div>
      </div>
    </div>
  );
};
