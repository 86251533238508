// @flow
import * as React from "react";
import { useRef } from "react";
import { useIsVisible } from "../hooks/useIsVisible";
import { scrollToSection } from "../utils/utils";

type Props = {};
export const Hero = (props: Props) => {
  const refTitle = useRef<any>();
  const isVisibleTitle = useIsVisible(refTitle);

  return (
    <div
      id={"hero"}
      className={
        "h-screen w-full pt-20 justify-center flex flex-col lg:flex-row-reverse items-center text-white lg:px-10"
      }
    >
      <div
        ref={refTitle}
        className={`transition-all ease-in duration-700 ${
          isVisibleTitle ? "scale-100 opacity-100" : "scale-75 opacity-0"
        }`}
      >
        <img
          src={"img/hero-img.png"}
          alt={"hero_img"}
          className={"w-96 lg:w-[500px] lg:animate-bounce-slow"}
        />
      </div>
      <div
        className={`mx-5 text-center lg:text-start transition-all ease-in duration-700 ${
          isVisibleTitle
            ? "translate-y-0 lg:translate-x-0 opacity-100"
            : "translate-y-20 lg:translate-y-0 lg:-translate-x-10 opacity-0"
        }`}
      >
        <h1 className={"text-5xl font-bold leading-10 mb-3"}>
          Marketing de éxito
        </h1>
        <div>
          <h2 className={"text-2xl text-stone-400 w-11/12 mx-auto lg:mx-0"}>
            Ayudamos a crecer tu negocio con ideas creativas, innovadoras y
            simples
          </h2>
        </div>
        <div>
          <button
            className={
              'mt-10 font-["Jost"] font-medium py-2 px-6 text-lg bg-golden transition-colors ease-in-out hover:bg-golden/80 duration-500 rounded-full'
            }
            onClick={() => scrollToSection("about")}
          >
            Empezamos
          </button>
        </div>
      </div>
    </div>
  );
};
