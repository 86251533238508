// @flow
import * as React from "react";
import { useRef } from "react";
import { Header } from "./header";
import { Hero } from "./hero";
import { Clients } from "./clients";
import { AboutUs } from "./about_us";
import { useIsVisible } from "../hooks/useIsVisible";
import { WhyUs } from "./why_us";
import { Skills } from "./skills";
import { Services } from "./services";
import { PushForward } from "./push_forward";
import { Portfolio } from "./portfolio";
import { ContactUs } from "./contact_us";
import { Footer } from "./footer";
import { Maintenance } from "./maintenance";

type Props = {};
export const Main = (props: Props) => {
  const refPointer = useRef<any>();
  const isVisibleTitle = useIsVisible(refPointer, false);

  return (
    <div
      className={
        "flex flex-col bg-gradient-to-bl from-stone-700 via-black to-black text-stone-400"
      }
    >
      {process.env.REACT_APP_MAINTENANCE === "1" ? (
        <Maintenance />
      ) : (
        <>
          <Header reachPoint={!isVisibleTitle} />
          <div ref={refPointer} id={"pointer"}></div>
          <Hero />
          <Clients />
          <AboutUs />
          <WhyUs />
          <Skills />
          <Services />
          <PushForward />
          <Portfolio />
          <ContactUs />
          <Footer />
        </>
      )}
    </div>
  );
};
