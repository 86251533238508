// @flow
import * as React from "react";
import { useMemo, useRef, useState } from "react";
import { useIsVisible } from "../hooks/useIsVisible";
// @ts-ignore
import ImageGallery from "react-image-gallery";
import { filter, findIndex, map } from "lodash";
import { XMarkIcon } from "@heroicons/react/24/solid";

type Props = {};
export const Portfolio = (props: Props) => {
  const refPortfolio = useRef<any>();
  const isVisibleTitle = useIsVisible(refPortfolio);
  const [open, setOpen] = useState(false);
  const [start, setStart] = useState(0);

  const images = [
    {
      id: 1,
      original: "img/portfolio/portfolio-1.jpg",
      tag: "Desarrollo",
      hide: false,
      alt: "Diseño de aplicacion web",
    },
    {
      id: 8,
      original: "img/portfolio/portfolio-8.jpg",
      tag: "Desarrollo",
      hide: false,
      alt: "Diseño de aplicacion mobil, aplicacion de ventas y control de finanzas",
    },
    {
      id: 2,
      original: "img/portfolio/portfolio-2.jpg",
      tag: "Personalización",
      hide: false,
      alt: "Botellas de agua personalizadas, impresion de logotipos",
    },
    {
      id: 4,
      original: "img/portfolio/portfolio-4.jpg",
      tag: "Desarrollo",
      hide: false,
      alt: "Desarrollo de aplicacion mobil, aplicacion de envio de alimentos y booking",
    },
    {
      id: 5,
      original: "img/portfolio/portfolio-5.jpg",
      tag: "Personalización",
      hide: false,
      alt: "Pullover personalizado, impresion de logotipos",
    },
    {
      id: 9,
      original: "img/portfolio/portfolio-9.jpg",
      tag: "Personalización",
      hide: false,
      alt: "Diseño de logotipos de marcas y negocios",
    },
    {
      id: 15,
      original: "img/portfolio/portfolio-15.jpg",
      tag: "Diseño",
      hide: false,
      alt: "Juguetes para niños",
    },
    {
      id: 10,
      original: "img/portfolio/portfolio-10.jpg",
      tag: "Audiovisuales",
      hide: false,
      alt: "Grabacion de audiovisuales y promociones",
    },
    {
      id: 11,
      original: "img/portfolio/portfolio-11.jpg",
      tag: "Audiovisuales",
      hide: false,
      alt: "Camaras profesionales",
    },
    {
      id: 12,
      original: "img/portfolio/portfolio-12.jpg",
      tag: "Audiovisuales",
      hide: false,
      alt: "Calidad de video y edicion",
    },
    {
      id: 13,
      original: "img/portfolio/portfolio-13.jpg",
      tag: "Diseño",
      hide: false,
      alt: "Diseño de interiores",
    },
    {
      id: 14,
      original: "img/portfolio/portfolio-14.jpg",
      tag: "Diseño",
      hide: false,
      alt: "Diseño de premios ",
    },
  ];

  const [imageList, setImageList] = useState<any[]>(images);
  const [selected, setSelected] = useState("Todos");

  const handleFilter = (value: string) => {
    const aux = map(images, (item) => {
      return { ...item, hide: false };
    });

    setSelected(value);

    if (value === "Todos") {
      setImageList(aux);
    } else
      setImageList(
        map(aux, (item) => {
          if (item.tag !== value) return { ...item, hide: true };
          return item;
        }),
      );
  };

  const galleryList = useMemo(() => {
    return filter(imageList, ({ hide }) => !hide);
  }, [imageList]);

  return (
    <>
      <div id={"portfolio"} className={"py-20 scroll-smooth"}>
        <div
          className={`px-6 transition-all ease-in duration-700 ${
            isVisibleTitle
              ? "translate-y-0 opacity-100"
              : "translate-y-20 opacity-0"
          }`}
        >
          <div className={"section-title relative pb-10"} ref={refPortfolio}>
            <h2 className={"text-4xl font-bold uppercase text-golden"}>
              Portafolio
            </h2>
          </div>

          <p className={"mt-6"}>
            Desde estrategias de marketing digital hasta diseño de marca y
            desarrollo de sitios web.
          </p>

          <div
            className={
              "my-6 font-['Jost'] font-medium flex flex-wrap gap-2 mx-auto justify-center"
            }
          >
            {[
              "Todos",
              "Diseño",
              "Personalización",
              "Desarrollo",
              "Audiovisuales",
            ].map((text) => (
              <button
                key={text}
                onClick={() => handleFilter(text)}
                className={`px-6 py-2 ${
                  selected === text ? "bg-golden" : ""
                } text-white rounded-full transition-colors ease-in-out hover:bg-golden/80 duration-500`}
              >
                {text}
              </button>
            ))}
          </div>

          <div
            className={
              "columns-1 md:columns-3 lg:mx-10 xl:mx-32 2xl:mx-80 gap-x-2"
            }
          >
            {map(
              imageList,
              ({ original, id, hide, tag }, index, collection) => (
                <div
                  key={id}
                  className={`relative transition-all ease-in-out duration-[1000ms] delay-200 overflow-hidden ${
                    hide ? "mb-0" : "mb-4"
                  }`}
                >
                  <div
                    className={`transition-all ease-in-out duration-[1000ms] flex justify-center delay-200 ${
                      hide
                        ? "transform opacity-0 w-0 scale-0 bg-red-600"
                        : "transform opacity-100 w-full scale-100"
                    }`}
                  >
                    <img
                      src={original}
                      alt={`gallery_${tag}`}
                      className={
                        "transition ease-in-out scale-100 duration-500 hover:scale-110"
                      }
                      onClick={() => {
                        setOpen(true);
                        setStart(
                          findIndex(
                            galleryList,
                            ({ id: this_id }) => this_id === id,
                          ),
                        );
                      }}
                    />
                  </div>
                </div>
              ),
            )}
          </div>
        </div>
      </div>
      {open && (
        <div className={"fixed z-20 w-full h-screen p-6 backdrop-blur-2xl"}>
          <div className={"flex justify-end"}>
            <button onClick={() => setOpen(false)}>
              <XMarkIcon className={"w-6"} />
            </button>
          </div>
          <ImageGallery
            startIndex={start}
            items={galleryList}
            lazyLoad={true}
            showIndex={true}
          />
        </div>
      )}
    </>
  );
};
