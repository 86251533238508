// @flow
import * as React from "react";
import { scrollToSection } from "../utils/utils";

type Props = {};
export const Footer = (props: Props) => {
  const linkClass = "hover:text-cyan-400";
  const titleClass = "text-2xl font-medium text-golden mb-4 font-['Jost']";

  return (
    <div
      id={"footer"}
      className={
        "pb-4 scroll-smooth grid md:grid-cols-2 lg:grid-cols-4 gap-8 mx-4 sm:mx-10 md:mx-14 xl:mx-32 2xl:mx-72"
      }
    >
      <div id={"data"} className={"text-start "}>
        <h4 className={titleClass}>Royall</h4>
        <p>Boyero</p>
        <p>Habana</p>
        <p>Cuba</p>
        <p className={"mt-4"}>
          <b>Teléfono: </b>+53 54425575
        </p>
        <p>
          <b>Email: </b>royallmarketing3@gmail.com
        </p>
      </div>

      <div id={"our_links"} className={"text-start"}>
        <h4 className={titleClass}>Nuestros Links</h4>

        <ul className={"list-disc list-inside ml-3"}>
          <li>
            <button
              className={linkClass}
              onClick={() => scrollToSection("hero")}
            >
              Royall
            </button>
          </li>
          <li>
            <button
              className={linkClass}
              onClick={() => scrollToSection("about")}
            >
              Sobre nosotros
            </button>
          </li>
          <li>
            <button
              className={linkClass}
              onClick={() => scrollToSection("services")}
            >
              Servicios
            </button>
          </li>
        </ul>
      </div>

      <div id={"our_services"} className={"text-start"}>
        <h4 className={titleClass}>Nuestros Servicios</h4>
        <ul className={"list-disc list-inside ml-3"}>
          {[
            { label: "Diseño" },
            { label: "Desarrollo" },
            { label: "Audiovisuales" },
            { label: "Marketing Digital" },
            { label: "Impresiones personalizadas" },
          ].map(({ label }) => (
            <li key={label}>
              <button
                className={linkClass}
                // onClick={() => scrollToSection("hero")}
              >
                {label}
              </button>
            </li>
          ))}
        </ul>
      </div>

      <div id={"our_socials"} className={"text-start "}>
        <h4 className={titleClass}>Nuestros Redes Sociales</h4>
        <p>Siguenos en nuestras redes, se parte de nuestra familia</p>
      </div>

      <div
        id={"copyright"}
        className={"text-start md:col-span-2 xl:col-span-4"}
      >
        <p>
          © Copyright <b>ROYALL</b> marketing. All Rights Reserved
        </p>
      </div>
    </div>
  );
};
