// @flow
import * as React from "react";
import { useRef } from "react";
import { useIsVisible } from "../hooks/useIsVisible";
import { map } from "lodash";
import {
  CalendarDaysIcon,
  ComputerDesktopIcon,
  GlobeAltIcon,
  NewspaperIcon,
  PaintBrushIcon,
  PrinterIcon,
  UsersIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/outline";

type CardProps = {
  title: string;
  icon: any;
  text: string;
};

const Card = ({ icon, text, title }: CardProps) => {
  return (
    <div
      className={
        "bg-white bg-opacity-90 rounded-sm w-full p-6 text-start space-y-2 text-black transition-all ease-in duration-300 translate-y-0 hover:-translate-y-2 h-72 md:h-[420px] scroll-smooth"
      }
    >
      <div>{icon}</div>
      <h3 className={"text-2xl font-medium font-['Jost']"}>{title}</h3>
      <p>{text}</p>
    </div>
  );
};

type Props = {};
export const Services = (props: Props) => {
  const refServices = useRef<any>();
  const isVisibleTitle = useIsVisible(refServices);

  const iconStyle = "w-12 text-golden";
  const services = [
    {
      title: "Marketing Digital",
      text: "Diagnóstico de Reputación Online, Diseño de estrategia de marketing digital, Community Manager, E-mail marketing, Análisis de redes sociales, Creación de contenidos.",
      icon: <GlobeAltIcon className={iconStyle} />,
    },
    {
      title: "Publicidad y Ventas",
      text: "Diseño Gráfico (Identidad corporativa, posters, creación de objetos promocionales), Presentaciones o lanzamientos de productos, Organización de participación en ferias comerciales, Organización de eventos publicitarios.",
      icon: <NewspaperIcon className={iconStyle} />,
    },
    {
      title: "Tecnologia",
      text: "Desarrollo de web sites, Desarrollo de aplicaciones para móviles (Android), Desarrollo de sistemas de gestión para pequeños negocios, Alojamiento web, Webmaster y Soporte.",
      icon: <ComputerDesktopIcon className={iconStyle} />,
    },
    {
      title: "Coaching",
      text: "Acompañamiento personalizado y orientado al desarrollo y crecimiento de las personas.",
      icon: <UsersIcon className={iconStyle} />,
    },
    {
      title: "Audiovisuales",
      text: "Creación de audiovisuales de alta calidad, spot publicitarios, documentales, videos clip, etc.",
      icon: <VideoCameraIcon className={iconStyle} />,
    },
    {
      title: "Impresión de objetos promocionales",
      text: "Impresión de pullover, gorras, llaveros, jarras, flyler, carteles lumínicos y más.",
      icon: <PrinterIcon className={iconStyle} />,
    },
    {
      title: "Diseño",
      text: "Diseño grafico, diseño de interiores, diseño de locales, carteles, pancartas.",
      icon: <PaintBrushIcon className={iconStyle} />,
    },
    {
      title: "Organización de eventos",
      text: "Organizamos eventos, realización de guiones, propaganda enfocada a eventos, entrevistas de prensa.",
      icon: <CalendarDaysIcon className={iconStyle} />,
    },
  ];

  return (
    <div id={"services"} className={"py-20 bg-white bg-opacity-10"}>
      <div
        className={`transition-all ease-in duration-700 ${
          isVisibleTitle
            ? "translate-y-0 opacity-100"
            : "translate-y-20 opacity-0"
        }`}
      >
        <div className={"section-title relative pb-10"} ref={refServices}>
          <h2 className={"text-4xl font-bold uppercase text-golden"}>
            Servicios
          </h2>
        </div>
        <p className={"mt-4"}>Amplia gama de servicios en un mismo lugar</p>
        <div
          className={
            "grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 lg:px-10 xl:px-40 2xl:px-80 px-5 mt-5 gap-4 items-stretch"
          }
        >
          {map(services, ({ title, text, icon }) => (
            <Card key={title} title={title} text={text} icon={icon} />
          ))}
        </div>
      </div>
    </div>
  );
};
