// @flow
import * as React from "react";
import { useRef } from "react";
import { useIsVisible } from "../hooks/useIsVisible";
import { scrollToSection } from "../utils/utils";

type Props = {};
export const PushForward = (props: Props) => {
  const refPush = useRef<any>();
  const isVisibleTitle = useIsVisible(refPush);

  return (
    <div id={"pus_forward"} className={"relative"}>
      <div
        style={{ backgroundImage: 'url("img/cta-bg.jpg")' }}
        className={"absolute w-full h-full bg-cover lg:bg-fixed opacity-20"}
      ></div>
      <div className={"py-40"}>
        <div
          className={`px-5 sm:px-20 md:px-40 xl:px-64 transition-all ease-in duration-700 lg:flex lg:flex-row ${
            isVisibleTitle
              ? "translate-y-0 opacity-100"
              : "translate-y-20 opacity-0"
          }`}
        >
          <div className={"lg:text-start lg:mr-20 xl:mr-40"}>
            <h2
              ref={refPush}
              className={"text-2xl font-bold uppercase text-golden"}
            >
              Impulsa tu éxito
            </h2>
            <div>
              <p>
                Descubre nuestro enfoque estratégico y creativo para hacer
                crecer tu negocio. Contáctanos hoy mismo y juntos alcanzaremos
                nuevas alturas.
              </p>
            </div>
          </div>
          <div className={"mt-6"}>
            <button
              className="border-2 py-2 px-8 border-golden rounded-full transition-colors ease-in-out hover:bg-golden duration-500"
              onClick={() => scrollToSection("hero")}
            >
              Royall
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
