// @flow
import * as React from "react";
import { useState } from "react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/solid";
import { scrollToSection } from "../utils/utils";

const Nav = ({
  alwaysShow = false,
  col = false,
}: {
  alwaysShow?: boolean;
  col?: boolean;
}) => {
  const linkClass = "hover:text-cyan-400";

  return (
    <nav id="navbar" className={`p-0 ${!alwaysShow && "hidden"} lg:block`}>
      <ul
        className={`flex ${
          col && "flex-col"
        } list-none gap-5 items-center font-['Jost']`}
      >
        <li>
          <button className={linkClass} onClick={() => scrollToSection("hero")}>
            Royall
          </button>
        </li>
        <li>
          <button
            className={linkClass}
            onClick={() => scrollToSection("about")}
          >
            Sobre nosotros
          </button>
        </li>
        <li>
          <button
            className={linkClass}
            onClick={() => scrollToSection("services")}
          >
            Servicios
          </button>
        </li>
        <li>
          <button
            className={linkClass}
            onClick={() => scrollToSection("portfolio")}
          >
            Portafolio
          </button>
        </li>

        <li>
          <button
            className={linkClass}
            onClick={() => scrollToSection("contact")}
          >
            Contáctanos
          </button>
        </li>
        <li>
          <button
            className="border-2 py-2 px-4 border-golden rounded-full transition-colors ease-in-out hover:bg-golden duration-500"
            onClick={() => scrollToSection("about")}
          >
            Bienvenidos
          </button>
        </li>
      </ul>
    </nav>
  );
};

type Props = { reachPoint: boolean };
export const Header = ({ reachPoint = false }: Props) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div
        className={`fixed top-0 w-full text-white z-10 bg-black transition-all ease-in duration-300 ${
          reachPoint ? "bg-opacity-80" : "bg-opacity-0"
        }`}
      >
        <div className={"mx-auto flex items-center justify-evenly py-4"}>
          <h1
            onClick={() => scrollToSection("hero")}
            className={
              "text-3xl uppercase font-medium tracking-wider select-none hover:cursor-pointer"
            }
          >
            Royall
          </h1>
          <img
            alt={"logo"}
            src={"img/logo.png"}
            onClick={() => scrollToSection("hero")}
            className={"max-h-10 hover:cursor-pointer"}
          />
          <Nav />

          <button className={"w-8 lg:hidden"} onClick={() => setOpen(true)}>
            <Bars3Icon />
          </button>
        </div>
      </div>

      {open && (
        <div
          onClick={() => setOpen(false)}
          className={"fixed z-20 w-full h-screen p-6 backdrop-blur-sm"}
        >
          <div className={"bg-white container rounded-xl mx-auto p-6 pb-10"}>
            <div className={"flex justify-end"}>
              <button onClick={() => setOpen(false)}>
                <XMarkIcon className={"w-6"} />
              </button>
            </div>
            <Nav alwaysShow={true} col={true} />
          </div>
        </div>
      )}
    </>
  );
};
