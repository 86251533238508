import { useEffect, useState } from "react";

export function useIsVisible(ref: any, temporal = true) {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (temporal) {
        if (entry.isIntersecting) {
          setIntersecting(true);
          observer.unobserve(ref.current);
        }
      } else setIntersecting(entry.isIntersecting);
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref, temporal]);

  return isIntersecting;
}
