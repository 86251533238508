// @flow
import * as React from "react";
import { useRef } from "react";
import { useIsVisible } from "../hooks/useIsVisible";

type Props = {};
export const AboutUs = (props: Props) => {
  const refAbout = useRef<any>();
  const isVisibleTitle = useIsVisible(refAbout);

  return (
    <div id={"about"} className={"py-20 scroll-smooth"}>
      <div
        className={`transition-all ease-in duration-700 ${
          isVisibleTitle
            ? "translate-y-0 opacity-100"
            : "translate-y-20 opacity-0"
        }`}
      >
        <div className={"section-title relative pb-10"} ref={refAbout}>
          <h2 className={"text-4xl font-bold uppercase text-golden"}>
            Sobre Nosotros
          </h2>
        </div>
        <div
          className={
            "grid lg:grid-cols-2 px-10 sm:px-40 xl:px-80 mt-10 text-justify gap-6 lg:gap-14"
          }
        >
          <div>
            <h3>
              Ofrecemos una alternativa refrescante para los negocios. Apostamos
              por la simplicidad como una poderosa herramienta para generar
              impacto y resultados positivos
            </h3>
            <ul className={"list-inside list-disc mt-4 space-y-2"}>
              <li className={"list-item"}>
                <b className={"text-golden"}>Creemos</b> que la simplicidad
                promueve la comprensión y la acción.
              </li>
              <li className={"list-item"}>
                <b className={"text-golden"}>Queremos</b> que nuestros clientes
                disfruten de una experiencia sencilla
              </li>
              <li className={"list-item"}>
                <b className={"text-golden"}>Creamos</b> estrategias y tácticas
                flexibles que se pueden ajustar fácilmente
              </li>
            </ul>
          </div>
          <h3>
            Al simplificar las estrategias de marketing, enfocamos los recursos
            limitados de manera más efectiva. Nos aseguramos de que cada acción
            y elemento aporte valor real y tenga un impacto significativo en el
            logro de los objetivos.
          </h3>
        </div>
      </div>
    </div>
  );
};
