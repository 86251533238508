// @flow
import * as React from "react";
import { useRef } from "react";
import { useIsVisible } from "../hooks/useIsVisible";

type PercentProps = {
  title: string;
  value: number;
  isVisible: boolean;
};

const PercentBar = ({ title, value, isVisible }: PercentProps) => {
  return (
    <div className={"space-y-2"}>
      <div className={"flex justify-between font-['Jost']"}>
        <p>{title}</p>
        <p>{value}%</p>
      </div>
      <div className={"bg-stone-500 h-3"}>
        <div
          style={{ width: `${value}%` }}
          className={`bg-golden h-3 w-['${value}%'] transition-all ease-in delay-300 duration-1000 ${
            isVisible ? "max-w-full" : "max-w-0"
          }`}
        ></div>
      </div>
    </div>
  );
};

type Props = {};
export const Skills = (props: Props) => {
  const refImage = useRef<any>();
  const isVisibleTitle = useIsVisible(refImage);

  const refGraph = useRef<any>();
  const isVisibleGraph = useIsVisible(refGraph);

  return (
    <div
      className={
        "px-5 sm:px-10 md:px-20 py-10 space-y-4 text-start lg:flex lg:flex-row lg:space-x-5 lg:justify-center"
      }
    >
      <img
        className={`transition-all ease-in duration-700 lg:w-[520px] ${
          isVisibleTitle ? "scale-100 opacity-100" : "scale-75 opacity-0"
        }`}
        ref={refImage}
        src={"img/skills.png"}
        alt={"Skills"}
      />
      <div className={"lg:space-y-2"}>
        <h2 className={"text-4xl font-['Jost'] font-medium"}>
          Nuestras principales competencias
        </h2>
        <h3>Nos gusta crecer y aprender con nuestros clientes. </h3>
        <div
          ref={refGraph}
          className={`space-y-4 transition-all ease-in duration-700 ${
            isVisibleGraph
              ? "translate-y-0 opacity-100"
              : "translate-y-10 opacity-0"
          }`}
        >
          <PercentBar isVisible={isVisibleGraph} title={"Diseño"} value={100} />
          <PercentBar
            isVisible={isVisibleGraph}
            title={"Audiovisuales"}
            value={98}
          />
          <PercentBar
            isVisible={isVisibleGraph}
            title={"Desarrollo de software"}
            value={95}
          />
          <PercentBar
            isVisible={isVisibleGraph}
            title={"SEO y SEM"}
            value={80}
          />
        </div>
      </div>
    </div>
  );
};
